@keyframes myAnim {
  0% {
    transform: scale(1) rotate(0);
  }
  100% {
    transform: scale(0.1) rotate(-360deg);
  }
}
.about-logo {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 20%;
  animation: myAnim 2s ease-out 0s 1 reverse;
}

.ioi-notification {
  background-color: #fa3e3e;
  border-radius: 2px;
  color: white;
  padding: 1px 3px;
  font-size: 10px;
  position: relative;
  top: 0;
  left: 0;
}

.ioi-notification-zero {
  background-color: #5b9b52;
  border-radius: 2px;
  color: white;
  padding: 1px 3px;
  font-size: 10px;
  position: relative;
  top: 0;
  left: 0;
}

.layout-main-section {
  min-height: 950px;
}

.rbroLogo {
  display: none;
}

.rbroContainer input[type=checkbox] {
  -webkit-appearance: auto !important;
     -moz-appearance: auto !important;
          appearance: auto !important;
}

@font-face {
  font-family: "dejavusans";
  src: url("/assets/silicon_ioi/fonts/dejavusans/DejaVuSans.ttf");
}
@font-face {
  font-family: "dejavusanscondensed";
  src: url("/assets/silicon_ioi/fonts/dejavusanscondensed/DejaVuSansCondensed.ttf");
}
@font-face {
  font-family: "dejavuserif";
  src: url("/assets/silicon_ioi/fonts/dejavuserif/DejaVuSerif.ttf");
}
@font-face {
  font-family: "dejavuserifcondensed";
  src: url("/assets/silicon_ioi/fonts/dejavuserifcondensed/DejaVuSerifCondensed.ttf");
}
@font-face {
  font-family: "opensans";
  src: url("/assets/silicon_ioi/fonts/opensans/OpenSans.ttf");
}
@font-face {
  font-family: "roboto";
  src: url("/assets/silicon_ioi/fonts/roboto/Roboto.ttf");
}
@font-face {
  font-family: "archivo";
  src: url("/assets/silicon_ioi/fonts/archivo/Archivo-Regular.ttf");
}
@font-face {
  font-family: "archivo_thin";
  src: url("/assets/silicon_ioi/fonts/archivo_thin/Archivo-Thin.ttf");
}
.fc .fc-toolbar.fc-header-toolbar {
  padding-top: 15px;
  display: flex;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
  display: flex;
}
.fc .fc-toolbar .fc-toolbar-chunk > div:first-child {
  display: flex;
}
.fc .fc-toolbar .fc-toolbar-title {
  margin-right: 8px;
  margin-left: 8px;
}

:root {
  --fc-button-bg-color: var(--fg-color);
  --fc-button-border-color: var(--gray-300);
  --fc-button-text-color: var(--gray-900);
  --fc-button-active-bg-color: var(--gray-300);
  --fc-button-active-border-color: var(--gray-300);
  --fc-button-hover-bg-color: #ffff;
  --fc-button-hover-border-color: #4C5A67;
}

.evp-popover {
  max-width: 450px;
}

.evp-popover .popover-body {
  padding: 0;
}

.evp-root {
  --evp-row-height: 38px;
  --evp-swatch-size: 12px;
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  font-size: 1rem;
  width: 450px;
  max-width: 90vw;
  --icon-stroke: currentColor;
}

.evp-row {
  display: flex;
  flex-direction: row;
  gap: 4px 12px;
  padding: 0px 16px;
}

.evp-row--compact {
  --evp-row-height: 24px;
  line-height: 16px;
}

.evp-row:nth-child(1 of .evp-row--field) {
  border-top: 1px solid var(--border-color);
  margin-top: 8px;
  padding-top: 8px;
}

.evp-row > div {
  flex: 1 1 auto;
}

.evp-row > div:last-child:not(:first-child) {
  padding: 4px 0px;
}

.evp-row > div:first-child:not(:last-child) {
  flex: 0 0 auto;
  width: var(--evp-row-height);
  height: var(--evp-row-height);
  display: flex;
  justify-content: center;
  align-items: center;
}

.evp-swatch {
  width: var(--evp-swatch-size);
  height: var(--evp-swatch-size);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
}

.evp-root [data-key=subject] {
  font-size: 1.2em;
  font-weight: 500;
}

.evp-root [data-key=timestamp] {
  font-size: 0.8em;
  opacity: 0.9;
}

.evp-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  margin: 6px;
}

.evp-btn {
  border-radius: 99px;
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.evp-btn:not(:hover, :focus-visible) {
  opacity: 0.6;
}

.evp-btn:hover,
.evp-btn:focus-visible {
  background-color: rgba(128, 128, 128, 0.2);
}

.evp-scroller {
  max-height: 420px;
  overflow-x: auto;
  padding-bottom: 16px;
}

.resource-event--main {
  align-self: flex-start;
  overflow: hidden;
  max-height: var(--resource-row-height, 100%);
}

body[data-route^="List/Event"] .tooltip {
  max-height: 420px;
  overflow-x: hidden;
}

.tooltip .tooltip-inner .ql-editor {
  --text-color: currentColor;
}

.fc .fc-toolbar.fc-header-toolbar {
  padding-top: 15px;
}
.fc .fc-toolbar .fc-toolbar-chunk > div:first-child {
  display: flex;
}
.fc .fc-toolbar .fc-toolbar-title {
  margin-right: 8px;
  margin-left: 8px;
}

:root {
  --fc-button-bg-color: var(--fg-color);
  --fc-button-border-color: var(--gray-300);
  --fc-button-text-color: var(--gray-900);
  --fc-button-active-bg-color: var(--gray-300);
  --fc-button-active-border-color: var(--gray-300);
}

[data-theme-mode=light][data-theme=light],
[data-theme-mode=automatic][data-theme=light] {
  /* Global change */
  --btn-primary: var(--primary) ;
  --invert-neutral: #24085c;
  /* Workspace */
  --ioi-heading-workspace-bg: #d0e5fb;
  --ioi-heading-workspace-color: #1f272e;
  /* List View */
  --ioi-heading-listview-bg: #a1cdf7;
  --ioi-list-odd-row-bg: #f5fafe;
  /* Form View */
  --ioi-heading-section-color: #1f272e;
  --ioi-heading-section-bg: #d0e7fb;
  /* Custom Table */
  --ioi-heading-grid-bg: #f4f5f6;
  --ioi-heading-grid-color: #1f272e;
  --ioi-content-grid-bg: #fff;
  --ioi-content-grid-color: #1f272e;
  /* Custom Grid */
  --ioi-bottom-total-grid-bg: #f4f5f6;
  --ioi-bottom-total-grid-color: #1f272e;
  --ioi-selected-row-background: #b1fcd9;
}

[data-theme-mode=dark][data-theme=dark],
[data-theme-mode=automatic][data-theme=dark] {
  /* Workspace */
  --ioi-heading-workspace-bg: gray;
  --ioi-heading-workspace-color: #f9fafa;
  /* List View */
  --ioi-heading-listview-bg: #a1cdf7;
  --ioi-list-odd-row-bg: #242a30;
  /* Form View */
  --ioi-heading-section-color: #1f272e;
  --ioi-heading-section-bg: #d0e7fb;
  /* Custom Table */
  --ioi-heading-grid-bg: #f4f5f6;
  --ioi-heading-grid-color: #1f272e;
  --ioi-content-grid-bg: #1f272e;
  --ioi-content-grid-color: #d0e7fb;
  /* Custom Grid */
  --ioi-bottom-total-grid-bg: #1f272e;
  --ioi-bottom-total-grid-color: #f9fafa;
  --ioi-selected-row-background: green;
}

[data-theme-mode=light-custom][data-theme=light] {
  /* Workspace */
  --ioi-heading-workspace-bg: #d7d0ff;
  --ioi-heading-workspace-color: #1f272e;
  /* List View */
  --ioi-heading-listview-bg: #d7d0ff;
  --ioi-list-odd-row-bg: #f0f0f0;
  /* Form View */
  --ioi-heading-section-bg: #d7d0ff;
  --ioi-heading-section-color: #1f272e;
  /* Custom Table */
  --ioi-heading-grid-bg: #f0f0f0;
  --ioi-heading-grid-color: #1f272e;
  --ioi-content-grid-bg: #fff;
  --ioi-content-grid-color: #1f272e;
  /* Custom Grid */
  --ioi-bottom-total-grid-bg: #f0f0f0;
  --ioi-bottom-total-grid-color: #1f272e;
  --ioi-selected-row-background: #b1fcd9;
}

[data-theme-mode=dark-custom][data-theme=dark] {
  /* Workspace */
  --ioi-heading-workspace-bg: #26cc8c;
  --ioi-heading-workspace-color: #f0f0f0;
  /* List View */
  --ioi-heading-listview-bg: #26cc8c;
  --ioi-list-odd-row-bg: #242a30;
  /* Form View */
  --ioi-heading-section-bg: #26cc8c;
  --ioi-heading-section-color: #f0f0f0;
  /* Custom Table */
  --ioi-heading-grid-bg: #f0f0f0;
  --ioi-heading-grid-color: #1f272e;
  --ioi-content-grid-bg: #1f272e;
  --ioi-content-grid-color: #f0f0f0;
  /* Custom Grid */
  --ioi-bottom-total-grid-bg: #f0f0f0;
  --ioi-bottom-total-grid-color: #1f272e;
  --ioi-selected-row-background: green;
}

:root {
  /* Workspace */
  /* List View */
  /* Form View */
  /* Custom Table */
  /* Logs Table */
}
:root .sidebar-item-container > .selected {
  background-color: var(--ioi-heading-workspace-bg) !important;
}
:root .sidebar-item-container > .selected .sidebar-item-label {
  color: var(--ioi-heading-workspace-color) !important;
}
:root [data-custom-widget-head=true] .widget-head {
  background-color: var(--ioi-heading-workspace-bg) !important;
}
:root [data-custom-widget-head=true] .widget-head .widget-label > * {
  color: var(--ioi-heading-workspace-color) !important;
}
:root [data-custom-header=true].ce-block:nth-child(1) {
  background-color: var(--ioi-heading-workspace-bg) !important;
  --heading-color: var(--ioi-heading-workspace-color) !important;
}
:root .page-form {
  background-color: var(--ioi-heading-listview-bg) !important;
}
:root .result .list-row-container:nth-child(odd) {
  background-color: var(--ioi-list-odd-row-bg) !important;
}
:root .page-title {
  overflow: visible;
}
:root [data-custom-section-head=true] {
  background-color: var(--ioi-heading-section-bg) !important;
  color: var(--ioi-heading-section-color) !important;
}
:root [data-custom-section-head=true] font, :root [data-custom-section-head=true] b {
  color: var(--ioi-heading-section-color) !important;
}
:root .page-title .title-area .title-text {
  margin-left: 5px;
}
:root[data-theme=dark] .tabulator .tabulator-row:hover .tabulator-cell {
  background-color: #808080 !important;
}
:root[data-theme=dark] .ioi-checkbox-table .tabulator-row.tabulator-selected {
  background-color: var(--ioi-content-grid-bg) !important;
}
:root[data-theme=light] .tabulator .tabulator-row:hover .tabulator-cell {
  background-color: #e4e4e4 !important;
}
:root[data-theme=light] .ioi-checkbox-table .tabulator-row.tabulator-selected {
  background-color: var(--ioi-content-grid-bg) !important;
}
:root [data-custom-grid=true] tr, :root [data-custom-grid=true] .tabulator-col {
  background-color: var(--ioi-heading-grid-bg) !important;
  color: var(--ioi-heading-grid-color) !important;
}
:root [data-custom-grid=true] .tabulator-row {
  background-color: var(--ioi-content-grid-bg) !important;
  color: var(--ioi-content-grid-color) !important;
}
:root [data-custom-grid=true] .tabulator-row.tabulator-selected {
  background-color: var(--ioi-selected-row-background) !important;
}
:root [data-custom-grid=true] .tabulator-header {
  font-weight: 400 !important;
}
:root div[data-custom-grid=true]:not(.tabulator) {
  background-color: var(--ioi-heading-grid-bg) !important;
  color: var(--ioi-heading-grid-color) !important;
}
:root [data-custom-grid-bottom=true] {
  background-color: var(--ioi-bottom-total-grid-bg) !important;
  color: var(--ioi-bottom-total-grid-color) !important;
}
:root [data-custom-grid-bottom=true] .tabulator-calcs-holder {
  border-bottom: none !important;
  border-top: none !important;
}
:root [data-custom-grid-bottom=true] .tabulator-calcs-holder .tabulator-cell {
  border-left: 1px solid var(--table-border-color) !important;
  background-color: var(--ioi-bottom-total-grid-bg) !important;
  color: var(--ioi-bottom-total-grid-color) !important;
}
:root [data-custom-grid-bottom=true] .tabulator-calcs-holder .tabulator-row {
  border-bottom: none !important;
}
:root [data-custom-log=true] {
  background-color: var(--ioi-heading-grid-bg) !important;
  color: var(--ioi-heading-grid-color) !important;
}
:root[data-theme-mode=light-custom] {
  /* Global change */
  --btn-primary: var(--primary) ;
  --invert-neutral: #24085c ;
  /* Global */
  --bg-color: #ffffff;
  --navbar-bg: #ffffff;
  --card-bg: #ffffff;
  --text-light: #1f272e;
  --primary: #927eff;
  /* Form View */
}
:root[data-theme-mode=light-custom] .btn-primary:focus {
  background-color: #927eff !important;
  box-shadow: none !important;
  opacity: 0.8;
}
:root[data-theme-mode=light-custom] .form-tabs-list {
  --card-bg: var(--ioi-heading-section-bg);
  --text-color: var(--ioi-heading-section-color);
  --text-muted: var(--ioi-heading-section-color);
  --primary: var(--ioi-heading-section-color);
}
:root[data-theme-mode=dark-custom] {
  /* Global */
  --bg-color: #1f272e;
  --navbar-bg: #1f272e;
  --card-bg: #1c2329;
  --text-dark: #f0f0f0;
  --primary: #20af78;
  /* Form View */
}
:root[data-theme-mode=dark-custom] .btn-primary:focus {
  background-color: #20af78 !important;
  box-shadow: none !important;
  opacity: 0.8;
}
:root[data-theme-mode=dark-custom] .form-tabs-list {
  --card-bg: var(--ioi-heading-section-bg);
  --text-color: var(--ioi-heading-section-color);
  --text-muted: var(--ioi-heading-section-color);
  --primary: var(--ioi-heading-section-color);
}

div[data-theme=light-custom] .background {
  background-color: #ffffff !important;
}
div[data-theme=light-custom] .navbar {
  background-color: #ffffff !important;
}
div[data-theme=light-custom] .toolbar .text {
  background-color: #1f272e !important;
}
div[data-theme=light-custom] .toolbar .primary {
  background-color: #927eff !important;
}
div[data-theme=light-custom] .foreground {
  background-color: #ffffff !important;
}

div[data-theme=dark-custom] .background {
  background-color: #1f272e !important;
}
div[data-theme=dark-custom] .navbar {
  background-color: #1f272e !important;
}
div[data-theme=dark-custom] .toolbar .text {
  background-color: #f0f0f0 !important;
}
div[data-theme=dark-custom] .toolbar .primary {
  background-color: #20af78 !important;
}
div[data-theme=dark-custom] .foreground {
  background-color: #1c2329 !important;
}

@media (min-width: 768px) {
  body.full-width .container {
    width: 98%;
  }
}
:root {
  --page-head-height: 90px!important;
}

@media (min-width: 768px) {
  .list-row-container .details-row {
    display: none;
  }
  .list-row-container .details-row-button {
    display: none;
  }
}
@media (max-width: 767px) {
  .list-row-container .list-row {
    width: 90%;
  }
  .list-row-container .details-row {
    width: 100%;
    padding: 0 50px !important;
  }
  .list-row-container {
    position: relative;
  }
  .list-row-container .details-row-button {
    position: absolute;
    left: 0;
    top: 0;
    width: 10%;
    height: 43px;
    padding-bottom: 2px;
    font-size: 22.5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .list-row-container .details-row:nth-child(2) {
    border-top: 2px solid var(--gray-100);
  }
  .list-row-container .details-row {
    pointer-events: none;
  }
}
.image-view-container .image-view-body img {
  background-color: var(--bg-color) !important;
  -o-object-fit: contain !important;
     object-fit: contain !important;
  -o-object-position: center !important;
     object-position: center !important;
}

.frappe-list .no-result .no-result .msg-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ioi_expert_view {
  font-size: var(--text-md) !important;
}
.ioi_expert_view .tabulator-header {
  border: none !important;
}
.ioi_expert_view .tabulator-header-filter input {
  border: none !important;
  background-color: var(--control-bg) !important;
  color: var(--text-color) !important;
  height: 26px !important;
  font-size: var(--text-md) !important;
  border-radius: var(--border-radius) !important;
  padding-right: 12px !important;
}
.ioi_expert_view .tabulator-header-contents {
  z-index: 1;
}
.ioi_expert_view .tabulator-header-contents::after {
  content: "";
  height: 60.5px;
  background-color: var(--ioi-heading-listview-bg) !important;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.ioi_expert_view [tabulator-field=responsiveCollapse] .tabulator-col-content:after {
  width: 0px !important;
}
.ioi_expert_view .tabulator-col .tabulator-col-content {
  display: flex !important;
  flex-direction: row-reverse !important;
  padding: 14px 12px 18.5px 12px !important;
}
.ioi_expert_view .tabulator-alert {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.ioi_expert_view .tabulator-alert .tabulator-alert-msg {
  border: none !important;
  color: #fff !important;
}
.ioi_expert_view .tabulator-alert .tabulator-alert-msg.tabulator-alert-state-msg {
  background: var(--ioi-heading-listview-bg) !important;
}
.ioi_expert_view .tabulator-alert .tabulator-alert-msg.tabulator-alert-state-error {
  background: #DC3545 !important;
}
.ioi_expert_view .tabulator-responsive-collapse table {
  width: 100%;
}
.ioi_expert_view .tabulator-responsive-collapse table tr {
  background-color: inherit !important;
  color: inherit !important;
}
.ioi_expert_view .tabulator-responsive-collapse table td {
  font-size: var(--text-md) !important;
  border: none !important;
}
.ioi_expert_view .tabulator-tableholder {
  background-color: var(--ioi-content-grid-bg) !important;
  color: var(--ioi-content-grid-color) !important;
  margin: auto !important;
  width: calc(100% - 10px) !important;
  display: flex;
}
.ioi_expert_view .tabulator-row {
  border: none !important;
  border-bottom: 1px solid #f5f5f5 !important;
}
.ioi_expert_view[data-custom-grid=true] .tabulator-col {
  background-color: var(--ioi-heading-listview-bg) !important;
}
.ioi_expert_view[data-custom-grid=true] .tabulator-col .tabulator-col-content:after {
  content: "";
  height: 30px;
  width: 2px;
  position: absolute;
  left: 0;
  top: 15px;
  background-color: #dee2e6;
}
.ioi_expert_view[data-custom-grid=true] .tabulator-row.tabulator-selected {
  background-color: var(--ioi-content-grid-bg) !important;
}
.ioi_expert_view .tabulator-col-title {
  font-weight: 400;
}
.ioi_expert_view .tabulator-row-odd .tabulator-cell {
  background-color: var(--ioi-list-odd-row-bg) !important;
}
.ioi_expert_view .tabulator-footer {
  background-color: var(--ioi-content-grid-bg) !important;
}
.ioi_expert_view .tabulator-footer .tabulator-page {
  padding: 4px 12px !important;
  margin: 10.5px 3px 10.5px 0 !important;
}
@media (max-width: 768px) {
  .ioi_expert_view .tabulator-footer-contents {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 425px) {
  .ioi_expert_view .tabulator-footer-contents [data-page=first] {
    display: none;
  }
  .ioi_expert_view .tabulator-footer-contents [data-page=last] {
    display: none;
  }
}

.kanban .disable-click * {
  pointer-events: all;
  cursor: not-allowed !important;
}
.kanban .kanban-card .kanban-image img {
  background-color: var(--bg-color) !important;
  -o-object-fit: contain !important;
     object-fit: contain !important;
  -o-object-position: center !important;
     object-position: center !important;
}

.row-dbl-title {
  height: 100% !important;
}
.row-dbl-title .col {
  border-bottom: 1px solid var(--table-border-color);
}

.row-dbl-data .col {
  border-bottom: 1px solid var(--table-border-color);
}
.row-dbl-data .rows .grid-row:first-child {
  border-top: 0px solid var(--table-border-color) !important;
}
.row-dbl-data .rows .grid-row:last-child {
  border-bottom: 1px solid var(--table-border-color) !important;
  margin-bottom: 0px;
}
.row-dbl-data .rows .grid-row:last-child .data-row.row {
  margin-bottom: -2px;
}
.row-dbl-data .rows .grid-row {
  border-bottom: 3px solid var(--table-border-color);
  border-top: 3px solid var(--table-border-color);
  margin-bottom: -5px;
}

.tabulator:not(#ioi_expert_view) {
  border-radius: 10px !important;
}
.tabulator:not(#ioi_expert_view) .tabulator-header {
  border-top: 0px solid var(--table-border-color) !important;
  border-bottom: 1px solid var(--table-border-color) !important;
}
.tabulator:not(#ioi_expert_view) .tabulator-header .tabulator-col-content {
  padding: 7.5px !important;
}
.tabulator:not(#ioi_expert_view) .tabulator-tableholder .tabulator-row {
  border-bottom: 1px solid var(--table-border-color) !important;
}
.tabulator:not(#ioi_expert_view) .tabulator-tableholder .tabulator-cell {
  border-left: 1px solid var(--table-border-color) !important;
}
.tabulator.wrap-lines-table .tabulator-cell {
  white-space: pre-wrap !important;
}
.tabulator.wrap-lines-table .tabulator-row .tabulator-cell {
  padding: 10px !important;
}
.tabulator input[type=checkbox] {
  margin-left: 0 !important;
}
.tabulator .tabulator-header-filter input {
  height: 26px !important;
  font-size: var(--text-md) !important;
  padding-right: 6px !important;
}

[class^=tabulator] {
  font-size: var(--text-md) !important;
}

#ioi_side_view .tabulator-responsive-collapse table, [id$=_list_side_view] .tabulator-responsive-collapse table {
  width: 100%;
}
#ioi_side_view .tabulator-responsive-collapse table tr, [id$=_list_side_view] .tabulator-responsive-collapse table tr {
  background-color: inherit !important;
  color: inherit !important;
}
#ioi_side_view .tabulator-responsive-collapse table td, [id$=_list_side_view] .tabulator-responsive-collapse table td {
  font-size: var(--text-md) !important;
  border: none !important;
}

#ioi_side_view, [id$=_list_side_view] {
  height: 950px;
  min-height: 600px;
  margin-right: 15px;
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  overflow: auto;
  position: relative;
}

#close_ioi_side_view, [id$=_close_list_side_view] {
  height: 36px;
}

#ioi_side_view_resizer, [id$=_list_side_view_resizer] {
  min-height: 600px;
  margin-top: 25px;
  width: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: ew-resize;
}

#ioi_side_view *, [id$=_list_side_view] * {
  font-size: var(--text-md) !important;
}

.pvtUi {
  border-collapse: inherit !important;
  border-spacing: 0 !important;
}
.pvtUi * {
  font-size: var(--text-md) !important;
  font-family: var(--font-stack) !important;
  color: var(--text-color) !important;
}
.pvtUi * :focus-visible {
  outline: none !important;
}
.pvtUi td {
  border-color: var(--table-border-color) !important;
}
.pvtUi .pvtTable tr th {
  background-color: var(--subtle-accent) !important;
  border-color: var(--table-border-color) !important;
  color: var(--gray-600) !important;
}
.pvtUi .pvtTable tr th:not(.pvtAxisLabel, .pvtTotalLabel) {
  font-weight: var(--weight-regular) !important;
}
.pvtUi .pvtTable tr td {
  border-color: var(--table-border-color) !important;
}
.pvtUi .pvtTable th, .pvtUi .pvtTable td {
  padding: 10px 8px !important;
}
.pvtUi .pvtAxisContainer, .pvtUi .pvtVals, .pvtUi .pvVal, .pvtUi .pvtTotal {
  background-color: var(--card-bg);
}
.pvtUi .pvtTotal {
  font-weight: var(--weight-regular) !important;
}
.pvtUi .pvtAttr {
  background-color: var(--control-bg) !important;
  color: var(--text-color) !important;
  border-radius: var(--border-radius) !important;
  padding: 5px 8px !important;
  border: none !important;
}
.pvtUi .pvtAttr .pvtTriangle {
  margin-right: 4px !important;
}
.pvtUi .pvtFilterBox {
  border-color: var(--table-border-color) !important;
  border-radius: var(--border-radius) !important;
  padding: 10px !important;
}
.pvtUi .pvtCheckContainer {
  overflow-y: auto !important;
  padding-top: 10px !important;
  border-top: 1px solid var(--table-border-color) !important;
  border-bottom: none !important;
  margin-top: 5px !important;
}
.pvtUi .pvtCheckContainer p {
  border-radius: var(--border-radius) !important;
  margin: 3px 0px !important;
}
.pvtUi .pvtCheckContainer p.selected {
  background-color: var(--control-bg) !important;
}
.pvtUi .pvtDropdown {
  background-color: var(--control-bg) !important;
  color: var(--text-color) !important;
  border-radius: var(--border-radius) !important;
  border: none !important;
  height: 30px !important;
  max-width: 70% !important;
  padding: 0px 5px !important;
  margin: 5px !important;
  font-weight: var(--weight-regular) !important;
  height: 28px !important;
}
.pvtUi .pvtRenderers {
  padding: 5px !important;
  background-color: var(--ioi-heading-section-bg) !important;
  border-top-right-radius: var(--border-radius) !important;
  height: 28px !important;
  border: 1px solid var(--ioi-heading-section-bg) !important;
  width: 15% !important;
}
.pvtUi .pvtOutput {
  padding: 32px 32px 0 0 !important;
}
.pvtUi .pvtUnused {
  background-color: var(--ioi-heading-section-bg) !important;
  border-top-left-radius: var(--border-radius) !important;
  border: 1px solid var(--ioi-heading-section-bg) !important;
}
.pvtUi .pvtCols {
  border-right: none !important;
}
.pvtUi .pvtRows {
  border-top: none !important;
}
.pvtUi .pvtAxisLabel, .pvtUi .pvtTotalLabel {
  font-weight: 600 !important;
}
.pvtUi .pvtButton {
  background-color: var(--control-bg) !important;
  color: var(--text-color) !important;
  border-radius: var(--border-radius) !important;
  border: none !important;
  margin: 4px !important;
}
.pvtUi input[type=text] {
  border: none !important;
  border-radius: var(--border-radius-sm) !important;
  padding: var(--input-padding) !important;
  background-color: var(--control-bg) !important;
  color: var(--text-color) !important;
}
.pvtUi .pvtDropdown:focus, .pvtUi .pvtButton:focus, .pvtUi input:focus {
  border-color: var(--gray-500) !important;
  outline: 0 !important;
  box-shadow: 0 0 0 2px rgba(124, 124, 124, 0.25) !important;
}

#pivot_chart {
  margin-top: 60px !important;
}
#pivot_chart .graph-svg-tip {
  background: var(--card-bg) !important;
}
#pivot_chart .graph-svg-tip .title, #pivot_chart .graph-svg-tip .title strong, #pivot_chart .graph-svg-tip .tooltip-value, #pivot_chart .graph-svg-tip .tooltip-label {
  font-size: 10px !important;
  font-weight: 600 !important;
}
#pivot_chart .graph-svg-tip .data-point-list li {
  display: flex;
  align-items: center;
}
#pivot_chart .y.axis g text {
  text-anchor: middle !important;
  rotate: -90deg !important;
  transform-origin: -15px 5px !important;
}
#pivot_chart .graph-svg-tip ul.data-point-list li,
#pivot_chart .graph-svg-tip ul.data-point-list li strong {
  font-size: 10px !important;
  font-weight: 600 !important;
}
#pivot_chart .chart-legend g text {
  font-size: 10px !important;
}